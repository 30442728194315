import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import numeral from "numeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import ContainerSection from "../../ratecards/components/ContainerSection";
import ToggleButton, { ToggleButtonGroup } from "../../ratecards/components/ToggleButton";
import { LEVEL_SELECTOR } from "../../../constants/css";
import { shouldShowCurrency, getCurrency } from "../components/SupportFunctions";
import ResultLevelCardPayRateDetailsTable from "./ResultLevelCardPayRateDetailsTable";
import ResultLevelCardMarkupDetailsTable from "./ResultLevelCardMarkupDetailsTable";
import ResultLevelCardBillRateDetailsTable from "./ResultLevelCardBillRateDetailsTable";
import LevelViewDisplayOptions from "./LevelViewDisplayOptions";
import { CONTINGENT_RATE_TYPE } from "../../ratecards/components/RateDisplayToggle";
import RateDisplayToggle from "../../ratecards/components/RateDisplayToggle";
import Modal from "../../ratecards/components/Modal";
import TextInput from "../../../components/lib/TextInput";
import { ModalButtons, ModalTitle } from "../../ratecards/components/FilterModal";
import Button from "../../../components/lib/Button";
import Tooltip from "../../../components/lib/Tooltip";
import Box from "../../../components/lib/Box";
import { InlineElements } from "../../../components/lib/Inline";
import Spacer from "../../../components/lib/Spacer";
import graphql from "babel-plugin-relay/macro";
import { BellCurveBillRates } from "../../ratesearch2/RsResults/Healthcare/BellCurveBillRates";

import { fetchQuery } from "react-relay";
import { useSearchResultsStore } from "../../../stores/SearchResults";

const SearchPeekQuery = graphql`
  query SearchPeekQuery($searchID: Int!) {
    viewer {
      savedsearch(id: $searchID) {
        rateType
        rates {
          rawBillRates
        }
        ...BellCurveBillRates_savedsearch
      }
    }
  }
`;

class SearchPeek extends React.Component {
  constructor() {
    super();
    this.state = { bellcurve: null, loadingBellcurve: false };
  }

  componentDidMount() {
    useSearchResultsStore.setState({ rateFrequency: "Hourly" });

    this.setState({ loadingBellcurve: true });
    fetchQuery(this.props.store.relayEnvironment, SearchPeekQuery, {
      searchID: this.props.search.id,
    }).subscribe({
      next: (response) => {
        this.setState({ loadingBellcurve: false, bellcurve: response });
      },
      error: (error) => {
        console.error(error);
        this.setState({ loadingBellcurve: false, bellcurve: null });
      },
    });
  }

  render() {
    const props = this.props;
    const store = props.store;
    const expanded = props.expanded;
    const search = props.search;

    const billRates = this.state.bellcurve?.viewer?.savedsearch.rates.rawBillRates;
    const showBellcurve = billRates && Array.isArray(billRates) && billRates.length > 0;

    const showRateDisplayToggle = props.showRateDisplayToggle || false;
    const disableRateChangesUI = props.disableRateChangesUI || false;
    let rateMultiplier =
      props.rateMultiplier || store.mobxStore.globalStateStore.rateMultiplier;
    const globalStateStore = showRateDisplayToggle && store.mobxStore.globalStateStore;
    const { extraActions } = props;

    let contingentRateFrequency = props.contingentRateFrequency;
    if (!contingentRateFrequency && showRateDisplayToggle && globalStateStore) {
      contingentRateFrequency = CONTINGENT_RATE_TYPE.HOURLY;
      if (
        !globalStateStore.customDailyMultiplierInvalid &&
        rateMultiplier === parseInt(globalStateStore.customDailyMultiplier)
      ) {
        contingentRateFrequency = CONTINGENT_RATE_TYPE.DAILY;
      } else if (
        !globalStateStore.customWeeklyMultiplierInvalid &&
        rateMultiplier === parseInt(globalStateStore.customWeeklyMultiplier)
      ) {
        contingentRateFrequency = CONTINGENT_RATE_TYPE.WEEKLY;
      } else if (
        !globalStateStore.customMonthlyMultiplierInvalid &&
        rateMultiplier === parseInt(globalStateStore.customMonthlyMultiplier)
      ) {
        contingentRateFrequency = CONTINGENT_RATE_TYPE.MONTHLY;
      }
    }

    if (search.frequency.id === 2) {
      // annual search should not multiply
      rateMultiplier = 1;
    }

    const className = classNames("ratecards search-peek", {
      "search-peek-expand": expanded,
      "search-peek-collapse": !expanded,
      notransition: search.store.disableAnimations,
    });

    if (search.network.loading || this.state.loadingBellcurve) {
      return (
        <div className={className}>
          <LoadingIndicator style={{ minHeight: 250 }} />
        </div>
      );
    } else if (search.network.error) {
      const error = search.network.error;
      if (error?.request.status === 403) {
        return (
          <div className={className}>
            <div style={{ padding: 20 }}>
              <h4>Sorry</h4>
              <p>You don't have permission to view this information</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className={className}>
            <h1>An error has occurred</h1>
            <pre>
              <code>{JSON.stringify(search.network.error)}</code>
            </pre>
            <button onClick={(e) => search.getSearchResults()}>Retry</button>
          </div>
        );
      }
    }

    const { Junior, Mid, Senior, Lead, Guru } = search.resultsByLevel;

    if (search.showThreeLevelsView && !Junior && !Mid && !Senior) {
      return (
        <div className={className}>
          <div className="search-peek-body">
            <DisplayOptions
              store={store}
              search={search}
              disableRateChangesUI={disableRateChangesUI}
              showRateDisplayToggle={showRateDisplayToggle}
            />
            <ContainerSection className="overlap-b-padding no-border search-peek-levels flex-wrap">
              <div className="alert alert-info no-items full-width" role="alert">
                <h3>Three Level View is not available</h3>
                <p>This search does not have all five standard levels</p>
              </div>
            </ContainerSection>
          </div>
        </div>
      );
    }

    return (
      <Box
        className={className}
        css={{ cursor: "default" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="search-peek-body">
          {!showBellcurve && (
            <DisplayOptions
              store={store}
              search={search}
              disableRateChangesUI={disableRateChangesUI}
              showRateDisplayToggle={showRateDisplayToggle}
              extraActions={extraActions}
            />
          )}

          {showBellcurve && (
            <BellCurveBillRates
              hideToggles
              savedsearch={this.state.bellcurve?.viewer?.savedsearch}
              isContract={this.state.bellcurve?.viewer?.savedsearch.rateType === 1}
            />
          )}
          {/* Display bellcurve */}
          {!showBellcurve && search.showCards && (
            <ContainerSection className="overlap-b-padding no-border search-peek-levels flex-wrap">
              {Junior !== null && (
                <SearchPeekItem
                  search={search}
                  resultLevel={Junior}
                  rateMultiplier={rateMultiplier}
                  contingentRateFrequency={contingentRateFrequency}
                />
              )}
              {Mid !== null && (
                <SearchPeekItem
                  search={search}
                  resultLevel={Mid}
                  rateMultiplier={rateMultiplier}
                  contingentRateFrequency={contingentRateFrequency}
                />
              )}
              {Senior !== null && (
                <SearchPeekItem
                  search={search}
                  resultLevel={Senior}
                  rateMultiplier={rateMultiplier}
                  contingentRateFrequency={contingentRateFrequency}
                />
              )}
              {Lead !== null && (
                <SearchPeekItem
                  search={search}
                  resultLevel={Lead}
                  rateMultiplier={rateMultiplier}
                  contingentRateFrequency={contingentRateFrequency}
                />
              )}
              {Guru !== null && (
                <SearchPeekItem
                  search={search}
                  resultLevel={Guru}
                  rateMultiplier={rateMultiplier}
                  contingentRateFrequency={contingentRateFrequency}
                />
              )}
            </ContainerSection>
          )}
          {!showBellcurve && !search.showCards && (
            <ContainerSection className="overlap-b-padding no-border search-peek-levels flex-wrap horizontal-scroll">
              <SearchPeekTable
                search={search}
                store={store}
                rateMultiplier={rateMultiplier}
              />
            </ContainerSection>
          )}
          {showRateDisplayToggle && (
            <Modal
              show={globalStateStore.customMultipliersModal.show}
              onHide={globalStateStore.onCancelCustomMultipliers}
            >
              <ContainerSection className="header">
                <ModalTitle>Custom Multipliers</ModalTitle>
              </ContainerSection>
              <ContainerSection>
                <label>Change multipliers for daily, weekly and monthly rates.</label>
              </ContainerSection>
              <ContainerSection>
                <div className="flex center-items">
                  <div style={{ width: 90 }}>
                    <label>Daily:</label>
                  </div>
                  <div style={{ flex: "1 1 auto" }}>
                    <TextInput
                      fill
                      color={
                        globalStateStore.customDailyMultiplierInvalid
                          ? "danger"
                          : "primary"
                      }
                      value={globalStateStore.customDailyMultiplier}
                      onChange={globalStateStore.onCustomDailyMultiplierChange}
                    />
                  </div>
                </div>
              </ContainerSection>
              <ContainerSection>
                <div className="flex center-items">
                  <div style={{ width: 90 }}>
                    <label>Weekly:</label>
                  </div>
                  <div style={{ flex: "1 1 auto" }}>
                    <TextInput
                      fill
                      color={
                        globalStateStore.customWeeklyMultiplierInvalid
                          ? "danger"
                          : "primary"
                      }
                      value={globalStateStore.customWeeklyMultiplier}
                      onChange={globalStateStore.onCustomWeeklyMultiplierChange}
                    />
                  </div>
                </div>
              </ContainerSection>
              <ContainerSection>
                <div className="flex center-items">
                  <div style={{ width: 90 }}>
                    <label>Monthly:</label>
                  </div>
                  <div style={{ flex: "1 1 auto" }}>
                    <TextInput
                      fill
                      color={
                        globalStateStore.customMonthlyMultiplierInvalid
                          ? "danger"
                          : "primary"
                      }
                      value={globalStateStore.customMonthlyMultiplier}
                      onChange={globalStateStore.onCustomMonthlyMultiplierChange}
                    />
                  </div>
                </div>
              </ContainerSection>
              <ContainerSection className="footer">
                <ModalButtons>
                  <Button
                    size="large"
                    color="brand"
                    onClick={globalStateStore.onApplyCustomMultipliers}
                  >
                    Apply
                  </Button>
                  <Button
                    size="large"
                    onClick={globalStateStore.onResetCustomMultipliers}
                  >
                    Reset
                  </Button>
                  <Button
                    size="large"
                    onClick={globalStateStore.onCancelCustomMultipliers}
                  >
                    Cancel
                  </Button>
                </ModalButtons>
              </ContainerSection>
            </Modal>
          )}
        </div>
      </Box>
    );
  }
}

const ChangeIcon = observer((props) => {
  const changeIcon = props.changeIcon;
  const changeType = props.changeType;

  // NOTE: Remove the minus icon completely when no change,
  if (changeIcon === "minus") {
    return null;
  }

  return <FontAwesomeIcon icon={changeIcon} className={changeType} />;
});

const LEVEL_EXPERIENCE = {
  Junior: "1-3 years",
  Mid: "3-5 years",
  Senior: "5-7 years",
  Lead: "7-10 years",
  Guru: "10+ years",
};

const SearchPeekTablePayRateRow = observer((props) => {
  const search = props.search;
  const resultLevel = props.resultLevel;
  const currencySymbol = props.currencySymbol;
  const threeLevelsView = props.threeLevelsView;
  const rateMultiplier = props.rateMultiplier;
  const change = resultLevel.change;
  const levelSelector = LEVEL_SELECTOR[resultLevel.levelName];
  const levelExperience = LEVEL_EXPERIENCE[resultLevel.levelName];

  if (search.showDeltas) {
    return (
      <tr key={resultLevel.levelName}>
        <td
          className={`no-wrap table-header-background ${levelSelector}`}
          style={{ fontWeight: 400 }}
        >
          Level {resultLevel.levelRomanNumeral}{" "}
          <span className="text-thin">({levelExperience})</span>
        </td>
        {/* Pay Rate */}
        <td
          className={`no-wrap table-border-left align-right ${change.payRateMinChangeType}`}
        >
          <ChangeIcon
            changeIcon={change.payRateMinChangeIcon}
            changeType={change.payRateMinChangeType}
          />
          {currencySymbol}{" "}
          {numeral(change.payRateMinChange * rateMultiplier).format("0,0.00")}
        </td>
        <td className={`no-wrap align-right ${change.payRateAvgChangeType}`}>
          <ChangeIcon
            changeIcon={change.payRateAvgChangeIcon}
            changeType={change.payRateAvgChangeType}
          />
          {currencySymbol}{" "}
          {numeral(change.payRateAvgChange * rateMultiplier).format("0,0.00")}
        </td>
        {!threeLevelsView && (
          <td className={`no-wrap align-right ${change.payRateMidChangeType}`}>
            <ChangeIcon
              changeIcon={change.payRateMidChangeIcon}
              changeType={change.payRateMidChangeType}
            />
            {currencySymbol}{" "}
            {numeral(change.payRateMidChange * rateMultiplier).format("0,0.00")}
          </td>
        )}
        <td
          className={`no-wrap table-border-right align-right ${change.payRateMaxChangeType}`}
        >
          <ChangeIcon
            changeIcon={change.payRateMaxChangeIcon}
            changeType={change.payRateMaxChangeType}
          />
          {currencySymbol}{" "}
          {numeral(change.payRateMaxChange * rateMultiplier).format("0,0.00")}
        </td>
      </tr>
    );
  }

  return (
    <tr key={resultLevel.levelName}>
      <td
        className={`no-wrap table-header-background ${levelSelector}`}
        style={{ fontWeight: 400 }}
      >
        Level {resultLevel.levelRomanNumeral}{" "}
        <span className="text-thin">({levelExperience})</span>
      </td>
      {/* Pay Rate */}
      <td className="no-wrap table-border-left align-right">
        <ChangeIcon
          changeIcon={change.payRateMinChangeIcon}
          changeType={change.payRateMinChangeType}
        />
        {currencySymbol}{" "}
        {numeral(resultLevel.payRateMin * rateMultiplier).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon
          changeIcon={change.payRateAvgChangeIcon}
          changeType={change.payRateAvgChangeType}
        />
        {currencySymbol}{" "}
        {numeral(resultLevel.payRateAvg * rateMultiplier).format("0,0.00")}
      </td>
      {!threeLevelsView && (
        <td className="no-wrap align-right">
          <ChangeIcon
            changeIcon={change.payRateMidChangeIcon}
            changeType={change.payRateMidChangeType}
          />
          {currencySymbol}{" "}
          {numeral(resultLevel.payRateMid * rateMultiplier).format("0,0.00")}
        </td>
      )}
      <td className="no-wrap table-border-right align-right">
        <ChangeIcon
          changeIcon={change.payRateMaxChangeIcon}
          changeType={change.payRateMaxChangeType}
        />
        {currencySymbol}{" "}
        {numeral(resultLevel.payRateMax * rateMultiplier).format("0,0.00")}
      </td>
    </tr>
  );
});

const SearchPeekTableBillRateRow = observer((props) => {
  const search = props.search;
  const resultLevel = props.resultLevel;
  const currencySymbol = props.currencySymbol;
  const rateMultiplier = props.rateMultiplier;
  const change = resultLevel.change;

  if (search.showDeltas) {
    return (
      <tr key={resultLevel.levelName}>
        {/* Bill Rate */}
        <td
          className={`no-wrap table-border-left align-right ${change.billRateMinChangeType}`}
        >
          <ChangeIcon
            changeIcon={change.billRateMinChangeIcon}
            changeType={change.billRateMinChangeType}
          />
          {currencySymbol}{" "}
          {numeral(change.billRateMinChange * rateMultiplier).format("0,0.00")}
        </td>
        <td className={`no-wrap align-right ${change.billRateAvgChangeType}`}>
          <ChangeIcon
            changeIcon={change.billRateAvgChangeIcon}
            changeType={change.billRateAvgChangeType}
          />
          {currencySymbol}{" "}
          {numeral(change.billRateAvgChange * rateMultiplier).format("0,0.00")}
        </td>
        <td className={`no-wrap align-right ${change.billRateMidChangeType}`}>
          <ChangeIcon
            changeIcon={change.billRateMidChangeIcon}
            changeType={change.billRateMidChangeType}
          />
          {currencySymbol}{" "}
          {numeral(change.billRateMidChange * rateMultiplier).format("0,0.00")}
        </td>
        <td
          className={`no-wrap table-border-right align-right ${change.billRateMaxChangeType}`}
        >
          <ChangeIcon
            changeIcon={change.billRateMaxChangeIcon}
            changeType={change.billRateMaxChangeType}
          />
          {currencySymbol}{" "}
          {numeral(change.billRateMaxChange * rateMultiplier).format("0,0.00")}
        </td>
      </tr>
    );
  }

  return (
    <tr key={resultLevel.levelName}>
      {/* Bill Rate */}
      <td className="no-wrap table-border-left align-right">
        <ChangeIcon
          changeIcon={change.billRateMinChangeIcon}
          changeType={change.billRateMinChangeType}
        />
        {currencySymbol}{" "}
        {numeral(resultLevel.billRateMin * rateMultiplier).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon
          changeIcon={change.billRateAvgChangeIcon}
          changeType={change.billRateAvgChangeType}
        />
        {currencySymbol}{" "}
        {numeral(resultLevel.billRateAvg * rateMultiplier).format("0,0.00")}
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon
          changeIcon={change.billRateMidChangeIcon}
          changeType={change.billRateMidChangeType}
        />
        {currencySymbol}{" "}
        {numeral(resultLevel.billRateMid * rateMultiplier).format("0,0.00")}
      </td>
      <td className="no-wrap table-border-right align-right">
        <ChangeIcon
          changeIcon={change.billRateMaxChangeIcon}
          changeType={change.billRateMaxChangeType}
        />
        {currencySymbol}{" "}
        {numeral(resultLevel.billRateMax * rateMultiplier).format("0,0.00")}
      </td>
    </tr>
  );
});

const SearchPeekTableMarkupRow = observer((props) => {
  const search = props.search;
  const resultLevel = props.resultLevel;
  const change = resultLevel.change;

  if (search.showDeltas) {
    return (
      <tr key={resultLevel.levelName}>
        {/* Markup */}
        <td
          className={`no-wrap table-border-left align-right ${change.markupPctMinChangeType}`}
        >
          <ChangeIcon
            changeIcon={change.markupPctMinChangeIcon}
            changeType={change.markupPctMinChangeType}
          />
          {numeral(change.markupPctMinChange).format("0,0.00")} %
        </td>
        <td className={`no-wrap align-right ${change.markupPctAvgChangeType}`}>
          <ChangeIcon
            changeIcon={change.markupPctAvgChangeIcon}
            changeType={change.markupPctAvgChangeType}
          />
          {numeral(change.markupPctAvgChange).format("0,0.00")} %
        </td>
        <td className={`no-wrap align-right ${change.markupPctMidChangeType}`}>
          <ChangeIcon
            changeIcon={change.markupPctMidChangeIcon}
            changeType={change.markupPctMidChangeType}
          />
          {numeral(change.markupPctMidChange).format("0,0.00")} %
        </td>
        <td
          className={`no-wrap table-border-right align-right ${change.markupPctMaxChangeType}`}
        >
          <ChangeIcon
            changeIcon={change.markupPctMaxChangeIcon}
            changeType={change.markupPctMaxChangeType}
          />
          {numeral(change.markupPctMaxChange).format("0,0.00")} %
        </td>
      </tr>
    );
  }

  return (
    <tr key={resultLevel.levelName}>
      {/* Markup */}
      <td className="no-wrap table-border-left align-right">
        <ChangeIcon
          changeIcon={change.markupPctMinChangeIcon}
          changeType={change.markupPctMinChangeType}
        />
        {numeral(resultLevel.markupPctMin).format("0,0.00")} %
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon
          changeIcon={change.markupPctAvgChangeIcon}
          changeType={change.markupPctAvgChangeType}
        />
        {numeral(resultLevel.markupPctAvg).format("0,0.00")} %
      </td>
      <td className="no-wrap align-right">
        <ChangeIcon
          changeIcon={change.markupPctMidChangeIcon}
          changeType={change.markupPctMidChangeType}
        />
        {numeral(resultLevel.markupPctMid).format("0,0.00")} %
      </td>
      <td className="no-wrap table-border-right align-right">
        <ChangeIcon
          changeIcon={change.markupPctMaxChangeIcon}
          changeType={change.markupPctMaxChangeType}
        />
        {numeral(resultLevel.markupPctMax).format("0,0.00")} %
      </td>
    </tr>
  );
});

const SearchPeekTable = observer((props) => {
  const store = props.store;
  const rateMultiplier = props.rateMultiplier;
  const search = props.search;
  const threeLevelsViewUser = store.threeLevelsViewUser;

  let rateType = search.frequency.title.toLowerCase();
  let currencySymbol = getCurrency(search).trim();

  // TODO: Use constants
  const showMarkupAndBillRate = rateType === "contract" && !search.showThreeLevelsView;

  const { Junior, Mid, Senior, Lead, Guru } = search.resultsByLevel;

  const levels = [Junior, Mid, Senior, Lead, Guru];

  const showingThreeLevels = threeLevelsViewUser && search.showThreeLevelsView;

  return (
    <div className="peek-table-container">
      <div className="flex-grid">
        <div className="flex-row no-gutters">
          <div className="flex-grid flex-auto">
            <div className="flex-row no-gutters no-wrap flex-auto">
              <div className="flex-col flex-auto">
                <table className="table table-striped pay-rate-table no-margin">
                  <thead className="table-double-border-bottom">
                    <tr>
                      <th className="table-header-no-background no-border" />
                      <th
                        className="table-header-no-background peek-table-header"
                        colSpan="4"
                      >
                        Pay Rate
                      </th>
                    </tr>
                    <tr>
                      <th className="table-header-no-background table-border-right table-no-border-top" />
                      {/* Pay Rate */}
                      <th className="table-border-left">
                        Min {search.minWageDisclaimerTooltip}
                      </th>
                      <th className="">Avg</th>
                      {!showingThreeLevels && <th className="">Mid</th>}
                      <th className="table-border-right">Max</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom table-border-left">
                    {levels.map((level) => {
                      if (!level) return null;

                      return (
                        <SearchPeekTablePayRateRow
                          key={level.id}
                          resultLevel={level}
                          currencySymbol={currencySymbol}
                          search={search}
                          store={store}
                          threeLevelsView={showingThreeLevels}
                          rateMultiplier={rateMultiplier}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {showMarkupAndBillRate && (
                <div className="flex-col flex-auto">
                  <table className="table table-striped markup-table no-margin">
                    <thead className="table-double-border-bottom">
                      <tr>
                        <th
                          className="table-header-no-background peek-table-header"
                          colSpan="4"
                        >
                          Suggested Markup{" "}
                          <Tooltip
                            side="top"
                            content={
                              <Box css={{ width: 250 }}>
                                Markups for this labor category will vary depending on
                                many factors including location, duration, volume of
                                business, type of labor, rebates, etc. Try the Negotiation
                                Worksheets to come up with markups for your unique needs.
                              </Box>
                            }
                          >
                            <span>
                              <FontAwesomeIcon
                                icon="info-circle"
                                style={{ marginLeft: 5 }}
                              />
                            </span>
                          </Tooltip>
                        </th>
                      </tr>
                      <tr>
                        {/* Markup */}
                        <th className="table-border-left">Min</th>
                        <th className="">Avg</th>
                        <th className="">Mid</th>
                        <th className="table-border-right">Max</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom">
                      {levels.map((level) => {
                        if (!level) return null;

                        return (
                          <SearchPeekTableMarkupRow
                            key={level.id}
                            resultLevel={level}
                            search={search}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {showMarkupAndBillRate && (
                <div className="flex-col flex-auto">
                  <table className="table table-striped bill-rate-table no-margin">
                    <thead className="table-double-border-bottom">
                      <tr>
                        <th
                          className="table-header-no-background peek-table-header"
                          colSpan="4"
                        >
                          Bill Rate{" "}
                          <Tooltip
                            id="billRateTooltip"
                            side="top"
                            content={
                              <Box css={{ width: 200 }}>
                                Bill Rates include all costs except VAT or GST tax.
                              </Box>
                            }
                          >
                            <span>
                              <FontAwesomeIcon
                                icon="info-circle"
                                style={{ marginLeft: 5 }}
                              />
                            </span>
                          </Tooltip>
                        </th>
                      </tr>
                      <tr>
                        {/* Bill Rate */}
                        <th className="table-border-left">Min</th>
                        <th className="">Avg</th>
                        <th className="">Mid</th>
                        <th className="table-border-right">Max</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom">
                      {levels.map((level) => {
                        if (!level) return null;

                        return (
                          <SearchPeekTableBillRateRow
                            key={level.id}
                            resultLevel={level}
                            currencySymbol={currencySymbol}
                            search={search}
                            rateMultiplier={rateMultiplier}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const SearchPeekItem = observer((props) => {
  const search = props.search;
  const rateMultiplier = props.rateMultiplier;
  const contingentRateFrequency = props.contingentRateFrequency;
  const resultLevel = props.resultLevel;

  let levelSelector = LEVEL_SELECTOR[resultLevel.levelName];

  const rateType = search.frequency.title.toLowerCase();

  let currencySymbol = "";
  let showCurrency = shouldShowCurrency(resultLevel);
  if (showCurrency) currencySymbol = getCurrency(search);

  let contingent_label = "per hour";
  if (contingentRateFrequency === CONTINGENT_RATE_TYPE.DAILY) {
    contingent_label = "per day";
  } else if (contingentRateFrequency === CONTINGENT_RATE_TYPE.WEEKLY) {
    contingent_label = "per week";
  } else if (contingentRateFrequency === CONTINGENT_RATE_TYPE.MONTHLY) {
    contingent_label = "per month";
  }

  return (
    <div
      className={`panel panel-details search-peek-level-card pull-left ${levelSelector}`}
    >
      <div className="panel-body">
        {/* Pay Rate */}
        <div className={classNames({ hidden: !search.showPayRate })}>
          <table className="table level-data">
            <thead>
              <tr>
                <th colSpan="2">
                  <span className="search-peek-level-name">
                    Level {resultLevel.levelRomanNumeral}
                  </span>
                </th>
                <th colSpan="2">Pay Rate</th>
              </tr>
            </thead>
            <tbody className="text-muted">
              <tr>
                <td className="max-value">
                  <div className="max-value-wrapper">
                    <header>
                      <h4>
                        {currencySymbol}{" "}
                        {numeral(resultLevel.payRateMax * rateMultiplier).format(
                          "0,0.00"
                        )}
                      </h4>
                      <h5 className="unit">Max Pay Rate</h5>
                    </header>
                    {rateType === "contract" && (
                      <div className="caption">{contingent_label}</div>
                    )}
                    {rateType === "fte" && <div className="caption">per year</div>}
                  </div>
                </td>
                <td colSpan="3" className="no-padding">
                  <ResultLevelCardPayRateDetailsTable
                    store={search}
                    search={search}
                    resultLevel={resultLevel}
                    currency={currencySymbol}
                    hideChangeIcons={!search.needsRatesUpdate}
                    rateMultiplier={rateMultiplier}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Markup */}
        <div className={classNames({ hidden: !search.showMarkup })}>
          <table className="table level-data">
            <thead>
              <tr>
                <th colSpan="2">
                  <span className="search-peek-level-name">
                    Level {resultLevel.levelRomanNumeral}
                  </span>
                </th>
                <th colSpan="2">Markup</th>
              </tr>
            </thead>
            <tbody className="text-muted">
              <tr>
                <td className="max-value">
                  <div className="max-value-wrapper">
                    <header>
                      <h4>{numeral(resultLevel.markupPctMax).format("0,0.00")}%</h4>
                      <h5 className="unit">Max Markup</h5>
                    </header>
                  </div>
                </td>
                <td colSpan="3" className="no-padding">
                  <ResultLevelCardMarkupDetailsTable
                    store={search}
                    resultLevel={resultLevel}
                    hideChangeIcons={!search.needsRatesUpdate}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Bill Rate */}
        <div
          className={classNames({
            hidden: !search.showBillRate,
          })}
        >
          <table className="table level-data bill-info">
            <thead>
              <tr>
                <th colSpan="2">
                  <span className="search-peek-level-name">
                    Level {resultLevel.levelRomanNumeral}
                  </span>
                </th>
                <th colSpan="2">
                  Bill Rate{" "}
                  <Tooltip
                    id="billRateTooltip"
                    side="top"
                    content={
                      <Box css={{ width: 200 }}>
                        Bill Rates include all costs except VAT or GST tax.
                      </Box>
                    }
                  >
                    <span>
                      <FontAwesomeIcon icon="info-circle" style={{ marginLeft: 5 }} />
                    </span>
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody className="text-muted">
              <tr>
                <td className="max-value">
                  <div className="max-value-wrapper">
                    <header>
                      <h4>
                        {currencySymbol}{" "}
                        {numeral(resultLevel.billRateMax).format("0,0.00")}
                      </h4>
                      <h5 className="unit">Max Bill Rate</h5>
                    </header>
                    <div className="caption">{contingent_label}</div>
                  </div>
                </td>
                <td colSpan="3" className="no-padding">
                  <ResultLevelCardBillRateDetailsTable
                    store={search}
                    resultLevel={resultLevel}
                    currency={currencySymbol}
                    hideChangeIcons={!search.needsRatesUpdate}
                    rateMultiplier={rateMultiplier}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

const DisplayOptions = observer((props) => {
  const { store, extraActions } = props;
  const search = props.search;
  const disableRateChangesUI = props.disableRateChangesUI || false;
  const rateType = search.frequency.title.toLowerCase();

  const canRevertLastUpdate = search.canRevertLatestUpdate;
  const hideUpdateOption = !search.needsRatesUpdate;

  const threeLevelsViewUser = store.threeLevelsViewUser;

  const showSectionOptions =
    rateType === "contract" && search.showCards && !search.showThreeLevelsView;
  return (
    <ContainerSection className="actions overlap-t-padding border-t">
      {extraActions}
      <InlineElements role="toolbar">
        <Spacer />
        {!disableRateChangesUI && (
          <div className="rc-actions-bar pull-left">
            <p
              className={classNames("text-muted text-x-small no-margin info", {
                hidden: !hideUpdateOption,
              })}
            >
              <FontAwesomeIcon icon="info-circle" fixedWidth className="icon" />
              <em>Rates are up to the latest available revision.</em>
            </p>
            <Button
              icon="undo"
              color="accent"
              css={{ display: !canRevertLastUpdate ? "none" : "inline-flex" }}
              onClick={() => {
                store.confirmUndoUpdateRatesOnSearchModal.search = search;
                store.confirmUndoUpdateRatesOnSearchModal.showModal();
              }}
            >
              Undo Last Update
            </Button>
            <Button
              icon="sync"
              color="brand"
              css={{ display: hideUpdateOption ? "none" : "inline-flex" }}
              onClick={() => {
                store.confirmUpdateRatesOnSearchModal.search = search;
                store.confirmUpdateRatesOnSearchModal.showModal();
              }}
            >
              Update Rates
            </Button>
          </div>
        )}

        {!disableRateChangesUI && <DataDisplayOptions search={search} />}

        {showSectionOptions && <SectionDisplayOptions search={search} />}

        {threeLevelsViewUser && (
          <LevelViewDisplayOptions
            store={search}
            onOptionChange={search.handleLevelViewDisplayOptionChange}
          />
        )}

        <ViewDisplayOptions search={search} />
        {props.showRateDisplayToggle && (
          <RateDisplayToggle
            selectedOption={
              store.mobxStore.globalStateStore.selectedContingentRateDisplayOption
            }
            onChange={store.mobxStore.globalStateStore.onContingentRatesDisplayChange}
            onConfig={store.mobxStore.globalStateStore.customMultipliersModal.showModal}
          />
        )}
      </InlineElements>
    </ContainerSection>
  );
});

const SectionDisplayOptions = observer((props) => {
  let search = props.search;
  return (
    <ToggleButtonGroup>
      <ToggleButton
        name="show-options"
        type="radio"
        value={search.SHOW_PAY_RATE_OPTION}
        selected={search.showPayRate}
        onClick={search.handleLevelPeekDisplayOptionChange}
      >
        Pay Rate
      </ToggleButton>
      <ToggleButton
        name="show-options"
        type="radio"
        value={search.SHOW_MARKUP_OPTION}
        selected={search.showMarkup}
        onClick={search.handleLevelPeekDisplayOptionChange}
      >
        Markup
      </ToggleButton>
      <ToggleButton
        name="show-options"
        type="radio"
        value={search.SHOW_BILL_RATE_OPTION}
        selected={search.showBillRate}
        onClick={search.handleLevelPeekDisplayOptionChange}
      >
        Bill Rate
      </ToggleButton>
    </ToggleButtonGroup>
  );
});

const DataDisplayOptions = observer((props) => {
  let search = props.search;
  return (
    <ToggleButtonGroup>
      <ToggleButton
        name="show-options"
        type="radio"
        value={search.SHOW_RATES_OPTION}
        selected={search.showRates}
        onClick={search.handleDataDisplayOptionChange}
      >
        Rates
      </ToggleButton>
      <ToggleButton
        name="show-options"
        type="radio"
        value={search.SHOW_DELTAS_OPTION}
        selected={search.showDeltas}
        onClick={search.handleDataDisplayOptionChange}
      >
        Change
      </ToggleButton>
    </ToggleButtonGroup>
  );
});

const ViewDisplayOptions = observer((props) => {
  let search = props.search;
  return (
    <ToggleButtonGroup>
      <ToggleButton
        name="show-options"
        type="radio"
        value={search.SHOW_TABLE_OPTION}
        selected={search.showTable}
        onClick={search.handleTabularDisplayOptionChange}
      >
        <FontAwesomeIcon icon="table" fixedWidth className="icon" /> Table
      </ToggleButton>
      <ToggleButton
        name="show-options"
        type="radio"
        value={search.SHOW_CARDS_OPTION}
        selected={search.showCards}
        onClick={search.handleTabularDisplayOptionChange}
      >
        <FontAwesomeIcon icon="list-alt" fixedWidth className="icon" /> Cards
      </ToggleButton>
    </ToggleButtonGroup>
  );
});

export default observer(SearchPeek);
